<template>
  <div>
    <!-- <Translator /> -->
    <!-- <span> Helllllo </span> -->
    <DiyoboInput
      class="notranslate"
      type="dropdown"
      name="language"
      noLabel
      placeholder="Select Language"
      :options="currentLanguages"
      :val="currentLanguage"
      @input="gTranslate"
      language
    />

    <!-- <div class="languages" v-for="language in languages" :key="language.title">
      <span class="language-item">
        <img
          :alt="
            language.altText ? language.altText : `${language.title} flag icon`
          "
          :src="`
          https://unpkg.com/language-icons/icons/${language.langCode}.svg`"
          class="flag"
        />
        <span class="languague__text">
          {{ language.title }}
        </span>
      </span>
    </div> -->
    <div id="google_translate_element"></div>
  </div>
</template>

<script>
const PROMOTERS = "promoters";
const BUYERS = "buyers";
import DiyoboInput from "@/components/DiyoboInput.vue";
// import cookies from "vue-cookies";
// import English from "language-icons/icons/en.svg";
// import Translator from "vue-gtranslate";
// import "vue-gtranslate/translator.css";

export default {
  name: "google-translate",
  components: {
    // Translator,
    DiyoboInput
  },
  data() {
    return {
      currentLanguage: "",
      languages: [
        {
          code: "en|af",
          langCode: "af",
          title: "Afrikaans"
        },
        {
          code: "en|sq",
          langCode: "sq",

          title: "Albanian"
        },
        {
          code: "en|ar",
          langCode: "ar",

          title: "Arabic"
        },
        {
          code: "en|hy",
          langCode: "hy",

          title: "Armenian"
        },
        {
          code: "en|az",
          langCode: "az",

          title: "Azerbaijani"
        },
        {
          code: "en|eu",
          langCode: "eu",

          title: "Basque"
        },
        {
          code: "en|be",
          langCode: "be",

          title: "Belarusian"
        },
        {
          code: "en|bg",
          langCode: "bg",

          title: "Bulgarian"
        },
        {
          code: "en|ca",
          langCode: "ca",

          title: "Catalan"
        },
        {
          code: "en|zh-CN",
          langCode: "zh",

          title: "Chinese (Simplified)"
        },
        {
          code: "en|zh-TW",
          langCode: "zh",

          title: "Chinese (Traditional)"
        },
        {
          code: "en|hr",
          langCode: "hr",

          title: "Croatian"
        },
        {
          code: "en|cs",
          langCode: "cs",

          title: "Czech"
        },

        {
          code: "en|da",
          langCode: "da",

          title: "Danish"
        },
        {
          code: "en|nl",
          langCode: "nl",

          title: "Dutch"
        },
        {
          code: "en|en",
          langCode: "en",

          title: "English"
        },
        {
          code: "en|et",
          langCode: "et",

          title: "Estonian"
        },
        {
          code: "en|tl",
          langCode: "tl",

          title: "Filipino"
        },
        {
          code: "en|fi",
          langCode: "fi",

          title: "Finnish"
        },
        {
          code: "en|fr",
          langCode: "fr",

          title: "French"
        },

        {
          code: "en|de",
          langCode: "de",

          title: "German"
        },
        {
          code: "en|el",
          langCode: "el",

          title: "Greek"
        },
        {
          code: "en|hu",
          langCode: "hu",

          title: "Hungarian"
        },
        {
          code: "en|hi",
          langCode: "hi",

          title: "Hindi"
        },
        {
          code: "en|id",
          langCode: "id",

          title: "Indonesian"
        },
        {
          code: "en|ga",
          langCode: "ga",

          title: "Irish"
        },
        {
          code: "en|it",
          langCode: "it",

          title: "Italian"
        },
        {
          code: "en|ja",
          langCode: "ja",

          title: "Japanese"
        },
        {
          code: "en|ko",
          langCode: "ko",

          title: "Korean"
        },
        {
          code: "en|lt",
          langCode: "lt",

          title: "Lithuanian"
        },
        {
          code: "en|ms",
          langCode: "ms",

          title: "Malay"
        },
        {
          code: "en|no",
          langCode: "no",

          title: "Norwegian"
        },
        {
          code: "en|pl",
          langCode: "pl",

          title: "Polish"
        },
        {
          code: "en|pt",
          langCode: "pt",

          title: "Portuguese"
        },
        {
          code: "en|ro",
          langCode: "ro",

          title: "Romanian"
        },
        {
          code: "en|ru",
          langCode: "ru",

          title: "Russian"
        },
        {
          code: "en|es",
          langCode: "es",

          title: "Spanish"
        },
        {
          code: "en|sv",
          langCode: "sv",

          title: "Swedish"
        },
        {
          code: "en|th",
          langCode: "th",

          title: "Thai"
        },
        {
          code: "en|tr",
          langCode: "tr",

          title: "Turkish"
        },
        {
          code: "en|uk",
          langCode: "uk",

          title: "Ukrainian"
        }
      ]
    };
  },
  methods: {
    gTranslate(code) {
      console.log('change', code);
      // Find language with key
      let fullForm = this.languages.find(lang => {
        return lang.langCode === code;
      });
      if (!fullForm) {
        code = 'en';
        fullForm = this.languages.find(lang => {
          return lang.langCode === code;
        });
      }
      this.currentLanguage = code;
      let langCode = `|en|${code}`;

      langCode = langCode.split("|").join("/");

      if ($cookies.isKey("googtrans")) {
        // remove previous cookie just in case
        $cookies.remove("googtrans");
        $cookies.set("googtrans", langCode);
        $cookies.set("languageFull", fullForm.title);
        $cookies.set("tttrans", code);
      } else {
        $cookies.set("googtrans", langCode);
        $cookies.set("languageFull", fullForm.title);
        $cookies.set("tttrans", code);
      }

      let t = document.querySelector(".goog-te-combo");
      if (!t) {
        new google.translate.TranslateElement(
          {
            pageLanguage: "en"
          },
          "google_translate_element"
        );
        t = document.querySelector(".goog-te-combo");
      }
      t.value = this.currentLanguage;
      this.GTranslateFireEvent(t, "change");
      // window.location.reload();
    },
    googleTranslateElementInit() {
      new google.translate.TranslateElement(
        {
          pageLanguage: "en"
        },
        "google_translate_element"
      );

      var t = document.querySelector(".goog-te-combo");
      t.value = this.currentLanguage;
      if (this.currentLanguage != "en") {
        this.GTranslateFireEvent(t, "change");
      }
    },
    createGoogleTranslateScript() {
      let googleTranslateScript = document.createElement("script");
      googleTranslateScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(googleTranslateScript);
    },
    getCookie(cookieName) {
      let cookie = {};
      document.cookie.split(";").forEach(function (el) {
        let [key, value] = el.split("=");
        cookie[key.trim()] = value;
      });
      return cookie[cookieName];
    },
    loadCurrentLanguage() {
      let cookie = this.getCookie("tttrans") || "en";
      let googtrans = this.getCookie("googtrans") || false;

      this.currentLanguage = googtrans ? cookie : "en";

      this.GTranslateFireEvent = (a, b) => {
        try {
          if (document.createEvent) {
            const c = document.createEvent("HTMLEvents");
            c.initEvent(b, true, true);
            a.dispatchEvent(c);
          } else {
            const c = document.createEventObject();
            a.fireEvent("on" + b, c);
          }
        } catch (e) {
          console.warn(`google transltate dispatch event has error: ${e}`);
        }
      };
    }
    // async updateCurrency() {
    //   if ($cookies.isKey("googtrans")) {
    //     const test = $cookies.get("googtrans").split("/");
    //     console.log(test[test.length - 1]);
    //   }
    // },
  },

  computed: {
    currentLanguages() {
      return this.languages.map(l => ({
        value: l.langCode,
        label: l.title,
        flag: l.langCode
      }));
    }
  },
  created() {
    this.loadCurrentLanguage();
  },

  mounted() {
    this.createGoogleTranslateScript();

    switch (this.$store.state.site) {
      case PROMOTERS:
        setTimeout(() => this.googleTranslateElementInit(), 4000);
        break;
      case BUYERS:
        setTimeout(() => this.googleTranslateElementInit(), 5000);
        break;
      default:
        setTimeout(() => this.googleTranslateElementInit(), 1430);
    }

    // let allLangs = this.document.ge;
  }
};
</script>

<style lang="less">
.left-navbar {
  .input-wrapper.notranslate {
    margin: 0;
    flex-wrap: nowrap;

    .dropdown-outer {
      .dropdown {
        transition: 0.7s linear all;
        width: fit-content;
        border-radius: 50px;
        padding: 5px;
        margin: 0;

        span.value {
          visibility: hidden;
          opacity: 0;
          display: none;
          transition: visibility 0s, opacity 0.5s linear;
        }

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          transition: 0.3s ease transform;
        }
      }

      &:hover {
        .dropdown {
          width: max-content;

          img {
            transform: rotate(-360deg);
            -webkit-transform: rotate(-360deg);
          }

          span.value {
            display: initial;
            visibility: visible;
            opacity: 1;
          }
        }
      }

      @media screen and (max-width: 600px) {
        &:hover {
          .dropdown {
            width: initial;

            span.value {
              display: none;
            }
          }
        }
      }
    }

    .dropdown-outer .options .options-inner {
      width: max-content;

      border-radius: 12px;
    }
  }
}

.language-item {
  display: flex;
}

.language__text {
  color: black;
  margin-top: 7px;
  padding-left: 5px;
  text-decoration: none;
}

#google_translate_element {
  width: 125px;
  float: right;
  text-align: left;
  display: none;

  background: @colors[navbar-background];
  color: var(--primary-green);
  font-weight: 700;

  cursor: pointer;
  padding-left: 4px;
}

.goog-te-combo {
  margin: 4px 0;
  border: 0;
  width: 50px;
  border-radius: 41px;
  cursor: pointer;
  transition: 0.5s ease width;
  padding: 10px;

  &:hover {
    width: 101px;
    height: initial;
  }
}

.goog-logo-link:link {
  display: none !important;
}

.goog-te-gadget {
  font-size: 0px !important;
}

.goog-gt-tt {
  display: none !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

body {
  top: 0px !important;
}
</style>
