<template>
  <div id="app" :class="theme" data-app>
    <alert-modal ref="alert" />
    <router-view></router-view>
  </div>
</template>

<script>
import {
  destroySession,
  getSessionCookie,
  SESSION_COOKIE,
} from "./helpers/auth.js";
import decode from "jwt-decode";
import { getLocationCookie, setLocationCookie } from "@/helpers/location";
import { getThemeCookie } from "@/helpers/theme";
import AlertModal from "@/components/modals/AlertModal.vue";
export default {
  name: "layout-splash",
  components: {
    AlertModal,
  },
  head() {
    return {
      title: "",
      titleTemplate: (title) =>
        title ? `${title} • Network Marketing Pro` : "Network Marketing Pro",
    };
  },
  data() {
    return {
      auth: false,
      hideStepper: true,
      redirectModal: false,
      redirectModalMsg: "",
      redirectModalLink: "",
      authenticated: false,
    };
  },
  methods: {
    checkCookieExpiration() {
      setInterval(() => {
        const myCookie = getSessionCookie();
        if (!myCookie && this.user.isAuthenticated) {
          this.$emit("logout");
          this.$store.dispatch("logout");
        }
      }, 600000);
    },
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    session_id: {
      get() {
        return this.$store.state.session_id;
      },
      set(id) {
        this.$store.state.session_id = id;
        return id;
      },
    },
    user() {
      return this.$store.state.user;
    },
    isStepper() {
      return true;
      let user = this.$store.state.user;
      user.hideBuyerStepper = user.hideBuyerStepper || false;
      if (user.isAuthenticated && !user.hideBuyerStepper) {
        this.hideStepper = false;
      } else {
        this.hideStepper = true;
      }
      return this.hideStepper;
    },
    embedPage() {
      if (this.$route.name === "event-guestlist-embed") {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.user.isAuthenticated && this.$route.query.redirect) {
      let redirectLink = this.$route.query.redirect;
      this.$router.push(redirectLink);
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      if (this.user.isAuthenticated) {
        this.checkCookieExpiration();
      }
      var cookie = document.cookie.split(";");
      var cookies = {};
      cookie.forEach((el) => {
        let keyvar = el.split("=");
        cookies[keyvar[0].trim()] = keyvar[1];
      });

      this.$axios.interceptors.request.use(
        (config) => {
          config.headers["Authorization"] = `Bearer ${getSessionCookie()}`;
          return config;
        },
        (error) => Promise.reject(error)
      );

      this.$axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === 401) {
            const cookie = getSessionCookie();
            if (cookie) {
              document.cookie = `${SESSION_COOKIE}=${cookie}; expires=Thu, 18 Dec 2013 12:00:00 UTC`;
            }
            sessionStorage.clear();
            destroySession(self);
            self.$notifications.disconnect();
            this.$store.dispatch("logout");

            if (!this.$route.meta.allowAnonymous) {
              this.$router.push("/");
            }
          } else if (error.response.status === 403) {
            this.$router.push("/brands");
          } else if (error.response.status === 400) {
            // TODO: implement better
            this.$refs.alert.open({
              title: "An Error Has Occured.",
              msg: "We're sorry to say but you have hit an error that has not been reported yet. We can help you out quickly though. Just message into our chat and we'll get your going.",
            });
          }

          // TODO: Once all the loader stuff is done we need to handle this a bit better
          this.$loader.stop();

          return Promise.reject(error);
        }
      );

      let self = this;
      this.$bus.$on("alert", (modal) => {
        this.$refs.alert.open(modal);
      });

      this.$bus.$on("redirectVerification", (id) => {
        this.$router.push(`/verify?id=${id}`);
        this.$toast("Please verify your email.", { type: "success" });
        return;
      });
      this.$store.state.bus.$on("alert", (alert) => {
        this.$refs.alert.open(alert);
      });

      this.$root.$on("show-diyobo-name-update-modal", () => {
        this.$refs.diyoboNameUpdateModal.open();
      });

      if (cookies[SESSION_COOKIE]) {
        this.$loader.start();
        try {
          const decoded = decode(cookies[SESSION_COOKIE]);
          this.$axios.post("/auth/verify").then((response) => {
            const data = response.data;
            if (data.authorized) {
              this.$store.commit("setUser", data.user);
              this.$store.commit("setData", decoded);

              let location = data.user.location;
              if (location && !getLocationCookie()) {
                if (location.split("-").length === 3) {
                  location = location.replace(/\-/g, "|");
                }

                const parts = location.split("|");

                if (parts.length === 3) {
                  setLocationCookie(parts[0], parts[1], parts[2]);
                }
              }

              const theme = getThemeCookie();
              if (theme === "system" && data.user.theme) {
                this.$store.commit("setTheme", data.user.theme);
              }

              const notificationsUrl =
                process.env.VUE_APP_NOTIFICATIONS_SOCKET +
                "?session_id=" +
                decoded.session_id;

              this.$notifications.connect(notificationsUrl);
              this.$notifications.init("buyers");

              if (
                this.user.diyobo &&
                this.user.first_name === "Your" &&
                this.user.last_name === "Name"
              ) {
                this.$root.$emit("show-diyobo-name-update-modal");
              }

              let q = this.$route.query;
              if (q.redirect) {
                this.$router.replace(q.redirect);
              }
            } else {
              const route = this.$route;
              // if (!route.meta.allowAnonymous && !isAuthenticated()) {
              //   this.$router.push({
              //     path: "/",
              //     query: { redirect: route.fullPath },
              //   });
              // }
            }

            this.authenticated = true;
            this.$store.state.authenticated = true;
            this.$loader.stop();
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        this.authenticated = true;
        this.$store.state.authenticated = true;
      }
    });
  },
};
</script>

<style>
body {
  background: var(--content-background);
}

#Loading {
  display: none;
}
ol {
  margin-left: 20px;
  margin-top: 15px;
}
ul {
  margin-left: 20px;
  margin-top: 15px;
}
</style>
